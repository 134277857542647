import { Grid, Box, TextField, Button } from '@mui/material'
import styled from '@emotion/styled'

function SectionContactUs() {
    return (
        <Box sx={{maxWidth: '1535px', width: '100%', margin: '40px auto 70px', padding: '0 15px', boxSizing: 'border-box'}} id='contact'>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={5}>
                    <TitleSection>Contact <span>Us</span></TitleSection>
                    <InputTextField id="full-name" placeholder="Full Name" variant="outlined" />
                    <InputTextField id="email" placeholder="Email address" variant="outlined" />
                    <InputTextField id="message" placeholder="Message" variant="outlined" />
                    <Submit>SUBMIT</Submit>
                </Grid>
                <ImageSection item xs={12} md={6} lg={7}>
                    <Img src='/images/contact.png' alt='contact' />
                </ImageSection>
            </Grid>
        </Box>
    )
}

const TitleSection = styled.h3`
    font-family: 'Chakra Petch', sans-serif;
    letter-spacing: -0.05em;
    color: #333333;
    font-weight: bold;
    font-size: 100px;
    line-height: 76%;
    margin: 0;
    margin-bottom: 50px;

    span {
        color: #3B62FF
    }

    @media screen and (max-width: 899px) {
        font-size: 70px;
        line-height: 76%;
    }
`
const ImageSection = styled(Grid)`
    display: flex;
    align-items: center;
    @media screen and (max-width: 899px) {
        display: none;
    }
`

const Img = styled.img` 
    width: 100%;
    max-width: 725px;
    display: block;
    margin-left: auto;
`

const InputTextField = styled(TextField)`
    width: 100%;
    margin-bottom: 34px;

    label {
        font-family: 'Chakra Petch', sans-serif;
        color: #333333;
        font-weight: normal;
        font-size: 18px;
        line-height: 134.09%;
    }

    input {
        font-family: 'Chakra Petch', sans-serif;
        background: #FFFFFF;
        border: 4px solid #000000;
        color: #333333;
        font-weight: normal;
        font-size: 18px;
        line-height: 134.09%;
    }

    .MuiOutlinedInput-notchedOutline {
        border: 0;
    }

    @media screen and (max-width: 899px) {
        label,
        input {
            font-size: 14px;
            line-height: 134.09%;
        }
    }
`

const Submit = styled(Button)`
    font-family: 'Chakra Petch', sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 76%;
    text-align: center;
    letter-spacing: -0.05em;
    color: #FFFFFF;
    background: #3B62FF;
    border-radius: 0;
    max-width: 279px;
    width: 100%;
    padding: 21px 0 20px;
    border: 4px solid #3B62FF;
    margin-left: auto;
    display: block;

    &:hover {
        color: #3B62FF;
        background: #FFFFFF;
    }

    @media screen and (max-width: 899px) {
        margin: auto;
        max-width: 157px;
        font-size: 20px;
        line-height: 76%;
    }
`

export default SectionContactUs