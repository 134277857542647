import PropTypes from 'prop-types'
import styled from '@emotion/styled'

function Burger({ setOpen, open }) {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
    </StyledBurger>
  )
}

const StyledBurger = styled.button`
  width: 38px;
  height: 38px;
  z-index: 110;
  display: none;
  border: none;
  cursor: pointer;
  background: transparent;
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  padding: 0;
  &:focus {
    outline: none;
  }
  div {
    width: 38px;
    height: 3px;
    background-color: #4D6CED;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(33deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'rotate(-33deg)' : 'rotate(0)'};
    }
  }
`;

Burger.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default Burger