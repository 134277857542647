import { Grid, Box, TextField, Button } from '@mui/material'
import styled from '@emotion/styled'
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

function SectionOurPartners() {
    return (
        <Box sx={{ borderTop: '4px solid #020308', borderBottom: '4px solid #020308' }} id='partners'>
            <SectionOurPartner>
                <TitleSection>Our <span>Partners</span></TitleSection>
                <Swiper
                    slidesPerView={2}
                    spaceBetween={10}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        600: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        900: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                        1200: {
                            slidesPerView: 6,
                            spaceBetween: 20,
                        },
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <Item>
                            <ImgSection>
                                <Img src='/images/Logo_1.png' alt='Logo_1' />
                            </ImgSection>
                            <TextSection>
                                <p className='hesman'>HESMAN</p>
                                <p className='studio'>studio</p>
                                <p className='about-us'>about us</p>
                            </TextSection>
                        </Item>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Item>
                            <ImgSection>
                                <Img src='/images/Logo_2.png' alt='Logo_2' />
                            </ImgSection>
                            <TextSection>
                                <p className='hesman'>HESMAN</p>
                                <p className='studio'>studio</p>
                                <p className='about-us'>about us</p>
                            </TextSection>
                        </Item>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Item>
                            <ImgSection>
                                <Img src='/images/Logo_3.png' alt='Logo_3' />
                            </ImgSection>
                            <TextSection>
                                <p className='hesman'>HESMAN</p>
                                <p className='studio'>studio</p>
                                <p className='about-us'>about us</p>
                            </TextSection>
                        </Item>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Item>
                            <ImgSection>
                                <Img src='/images/Logo_4.png' alt='Logo_4' />
                            </ImgSection>
                            <TextSection>
                                <p className='hesman'>HESMAN</p>
                                <p className='studio'>studio</p>
                                <p className='about-us'>about us</p>
                            </TextSection>
                        </Item>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Item>
                            <ImgSection>
                                <Img src='/images/Logo_5.png' alt='Logo_5' />
                            </ImgSection>
                            <TextSection>
                                <p className='hesman'>HESMAN</p>
                                <p className='studio'>studio</p>
                                <p className='about-us'>about us</p>
                            </TextSection>
                        </Item>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Item>
                            <ImgSection>
                                <Img src='/images/Logo_6.png' alt='Logo_6' />
                            </ImgSection>
                            <TextSection>
                                <p className='hesman'>HESMAN</p>
                                <p className='studio'>studio</p>
                                <p className='about-us'>about us</p>
                            </TextSection>
                        </Item>
                    </SwiperSlide>
                </Swiper>
            </SectionOurPartner>
        </Box>
    )
}

const SectionOurPartner = styled(Box)`
    background-image: url('/images/our-partner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 70px 15px 110px;

    .swiper-pagination {
        display: none;
    }
`

const TitleSection = styled.h3`
    font-family: 'Chakra Petch', sans-serif;
    letter-spacing: -0.05em;
    color: #333333;
    font-weight: bold;
    font-size: 100px;
    line-height: 76%;
    margin: 0;
    margin-bottom: 35px;
    text-align: center;

    span {
        color: #3B62FF
    }

    @media screen and (max-width: 899px) {
        font-size: 70px;
        line-height: 76%;
    }
`

const ImgSection = styled(Box)`
    background-color: #000000;
    margin: 4px;
`

const TextSection = styled(Box)`
    border-top: 3px solid #000000;

    p {
        font-family: 'Chakra Petch', sans-serif;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        font-weight: bold;
        font-size: 34px;
        line-height: auto;
        margin: 0;

        &.studio {
            font-size: 40px;
        }

        &.about-us {
            font-size: 29px;
        }
    }

    @media screen and (max-width: 899px) {
        p {
            font-size: 23px;
    
            &.studio {
                font-size: 24px;
            }
    
            &.about-us {
                font-size: 18   px;
            }
        }
    }
`

const Img = styled.img` 
    width: 100%;
    max-width: 167px;
    display: block;
    margin: auto;
    padding: 10px;
    box-sizing: border-box;
`

const Item = styled(Box)`
    width: 100%;
    border: 3px solid #000000;
    background-color: #ffffff;
    box-sizing: border-box;
`

export default SectionOurPartners