import { Grid, Box } from '@mui/material'
import TitleSection from 'components/display/Section/Title'
import styled from '@emotion/styled'

function SectionOurMission() {
  return (
    <WrapperSection id='hesmanLegend'>
      <Box sx={{marginLeft: {xs: 0, md: '20px'}}}>
      <Grid container spacing={2} flexDirection={{xs: 'column', md: 'row-reverse'}}>
        <Grid item xs={12} md={4}>
          <Box sx={{
            border: '4px solid #020308',
            borderLeft: {xs: 0, md: '4px solid #020308'},
            borderRight: 0,
            height: '90vh',
            backgroundImage: 'url("/images/bg-our-vision.png")',
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: {xs: '0 20px', sm: '0 40px 0 60px'}
          }}>
            <Box sx={{ width: '100%' }}>
              <TitleSection firstTitle={'Our'} lastTitle={'Vision'} align={'left'} />
            </Box>
            <div>Heatherwick Studio is a team of over 200 problem solvers dedicated to making the physical world around us better for everyone. Based out of our combined workshop and design studio in Central London, we create buildings, spaces, master-plans, objects and infrastructure. Focusing on large scale projects in cities all over the world, we prioritise those with the greatest positive social impact.</div>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{
            border: '4px solid #020308',
            borderLeft: {xs: 0, md: '4px solid #020308'},
            borderRight: {xs: 0, md: '4px solid #020308'},
            height: '90vh',
            backgroundImage: 'url("/images/bg-our-mission.png")',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0 20px'
          }} >
            <TitleSection firstTitle={'Our'} lastTitle={'Mission'} align={'center'} />
            <Box sx={{ color: '#3B62FF', fontSize: '36px', fontWeight: 'bold' }} mt={3}>TIÊN PHONG</Box>
            <Box sx={{ fontSize: '36px', fontWeight: 'bold', textAlign: 'center' }}>CHO CÁC DỰ ÁN</Box>
            <Box sx={{ color: '#3B62FF', fontSize: {xs: '170px', lg: '200px', xl: '245px'}, fontWeight: 'bold', lineHeight: 0.8 }}>NFT</Box>
            <Box sx={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>ĐƯA TÁC PHẨM NGHỆ THUẬT VIỆT RA THỊ TRƯỜNG QUỐC TẾ</Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{
            border: '4px solid #020308',
            borderLeft: {xs: 0, md: '4px solid #020308'},
            borderRight: {xs: 0, md: '4px solid #020308'},
            height: '90vh',
            backgroundImage: 'url("/images/bg-comic-our-mission.png")',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            position: 'relative',
          }} >
            <Box sx={{
              position: 'absolute',
              bottom: 0,
              right: {xs: '0', md: '-20px'},
              zIndex: 1,
              height: '50vh',
              width: {xs: '100%', md: 'calc(100% + 40px)'},
              backgroundImage: 'url("/images/comic-megazine.png")',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
            }} />
            <Box sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              zIndex: 1,
              height: '70vh',
              width: '100%',
              backgroundImage: 'url("/images/HuyHung.png")',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
            }} />
          </Box>
        </Grid>
      </Grid>
      </Box>
    </WrapperSection>
  )
}

const WrapperSection = styled(Box)`
  min-height: 90vh;
  margin-top: 20px;
  margin-bottom: 20px;
`

export default SectionOurMission