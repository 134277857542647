import React, { useEffect, useState } from "react";
import styled from '@emotion/styled'
import { Box, Button } from '@mui/material'
import { Link } from "react-router-dom";

function ComingSoon(props) {
    return (
        <>
            <ComingSoonContainer />
            <Description>
                <Box className="description">
                    we are going to launch our website very soon. please come back later.
                </Box>
            </Description>
        </>
    );
}

const ComingSoonContainer = styled(Box)`
    background-image: url('/images/Background-coming-soon.png');
    height: calc(100vh - 205px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 1199px) {
        height: calc(100vh - 274px);
    }

    @media screen and (max-width: 899px) {
        height: calc(100vh - 166px);
    }

    @media screen and (max-width: 800px) {
        height: calc(100vh - 196px);
    }

    @media screen and (max-width: 412px) {
        height: calc(100vh - 226px);
    }
`

const Description = styled(Box)`
    border-top: 6px solid #000000;
    border-bottom: 6px solid #000000;
    padding-top: 7px;
    background-color: #D1D1D1;
    box-sizing: border-box;
    padding-bottom: 7px;

    .description {
        font-family: 'Chakra Petch', sans-serif;
        color: #333333;
        text-align: center;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 34px;
        line-height: 203%;
        background-color: #FFFFFF;
        padding-left: 10px;
        padding-right: 10px;
    }

    @media screen and (max-width: 899px) {
        .description {
            font-size: 20px;
            line-height: 150%;
        }
    }
`

export default ComingSoon;