import styled from '@emotion/styled'

function TitleSection({firstTitle, lastTitle, align = 'left'}) {
  return (
    <StyledTittleSection align={align}>
      <StyledFirstTittle>{firstTitle}</StyledFirstTittle>
      <StyledLastTittle>{lastTitle}</StyledLastTittle>
    </StyledTittleSection>
  )
}

const StyledTittleSection = styled.h2`
  font-size: 70px;
  line-height: 0.8;
  margin-bottom: 10px;
  text-align: ${({ align }) => align === 'left' ? 'left' : align};
  @media (min-width: 1535px) {
    font-size: 100px;
  }
`

const StyledFirstTittle = styled.span`
  display: block;
`

const StyledLastTittle = styled.span`
  display: block;
  color: #3B62FF;
`

export default TitleSection;