export const heroSlide = [
  {
    img: '/images/HM-Banner-03 1.png',
  },
  {
    img: '/images/HM-Banner-03 2.png',
  },
  {
    img: '/images/HM-Banner-03 3.png',
  },
  {
    img: '/images/HM-Banner-03 1.png',
  },
]

export const teamSlide = [
  {
    img: '/images/Avatar-HungLan1.png',
    name: 'Hung Lan 0',
    position :'ART DIRECTOR',
  },
  {
    img: '/images/Avatar-HungLan1.png',
    name: 'Hung Lan',
    position :'ART DIRECTOR',
  },
  {
    img: '/images/Avatar-HungLan2.png',
    name: 'Hung Lan 2',
    position :'ART DIRECTOR',
  },
  {
    img: '/images/Avatar-HungLan3.png',
    name: 'Hung Lan 3',
    position :'ART DIRECTOR',
  },
  {
    img: '/images/Avatar-HungLan4.png',
    name: 'Hung Lan 4',
    position :'ART DIRECTOR',
  },

]