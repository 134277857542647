import { Grid, Box } from '@mui/material'
import TitleSection from 'components/display/Section/Title'
import styled from '@emotion/styled'

function SectionAbout() {
  return (
    <WrapperSection id='hesmanStudio'>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Box sx={{
            display: {xs: 'none', md: 'block'},
            border: '4px solid #020308',
            borderLeft: 0,
            height: '90vh',
            backgroundImage: 'url("/images/about-1.png")',
            backgroundSize: 'cover',
          }} />
        </Grid>
        <Grid item container sx={12} md={10} spacing={2}>
          <Grid item xs={12} md={6}>
            <Card sx={{
              border: '4px solid #020308',
              borderRight: {xs: 0, md: '4px solid #020308'},
              borderLeft: {xs: 0, md: '4px solid #020308'},
              alignItems: {xs: 'inherit', sm: 'center'},
              padding: {xs: '40px 20px 0', sm: '0 40px 0 80px'},
            }}>
              <Box sx={{
                position: 'relative'
              }}>
                <TitleSection firstTitle={'About'} lastTitle={'Studio'} />
                <Box sx={{fontSize: '24px', fontWeight: 'bold', marginBottom: '15px'}}>The new NFT Colletions of Legendary Hesman Comic</Box>
                <Box sx={{
                  position: 'relative'
                }}>
                  Heatherwick Studio is a team of over 200 problem solvers dedicated to making the physical world around us better for everyone. Based out of our combined workshop and design studio in Central London, we create buildings, spaces, master-plans, objects and infrastructure. Focusing on large scale projects in cities all over the world, we prioritise those with the greatest positive social impact.
                  <Box sx={{
                    position: 'absolute',
                    top: {xs: 'calc(100% + 60px)', md: 0},
                    left: {xs: 'calc(50% - 82px)', md: '-200px'},
                    width: '165px',
                    height: '150px',
                    zIndex: 1,
                    backgroundImage: 'url("/images/stamp.png")',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                  }} />
                </Box>

                <Box sx={{
                  position: 'absolute',
                  top: {xs: '70px', xl: '160px'},
                  left: '-40px',
                  width: '150px',
                  height: '125px',
                  zIndex: -1,
                  backgroundImage: 'url("/images/quote.png")',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                }} />

              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{
              border: '4px solid #020308',
              borderRight: 0,
              borderLeft: {xs: 0, md: '4px solid #020308'},
              height: '90vh',
              position: 'relative',
              backgroundImage: 'url("/images/about-2.png")',
              backgroundSize: 'cover',
            }}>
              <Box sx={{
                position: 'absolute',
                bottom: '-4px',
                right: 0,
                zIndex: 1,
                height: '50vh',
                width: '110%',
                backgroundImage: 'url("/images/bang.png")',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right bottom',
              }} />
              <Box sx={{
                position: 'absolute',
                bottom: {xs: '-50px', md: '-10%'},
                left: {xs: '0', md: '-100px'},
                zIndex: 2,
                height: '90vh',
                width: '90%',
                backgroundImage: 'url("/images/Lanh-Chua-Oman 1.png")',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
              }} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </WrapperSection>
  )
}

const Card = styled(Box)`
  min-height: 90vh;
  position: relative;
  display: flex;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    height: 50%;
    background-image: url("images/cloud1.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right top;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 20%;
    background-image: url("images/cloud2.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left bottom;
  }
`

const WrapperSection = styled(Box)`
  min-height: 90vh;
  margin-top: 20px;
  margin-bottom: 20px;
`

export default SectionAbout