import React, { useEffect, useState } from "react";
import styled from '@emotion/styled'
import { Box, Button } from '@mui/material'
import { Link } from "react-router-dom";

function PageNotFound(props) {
    return (
        <WrapPageNotFound>
            <PageNotFoundContainer>
                <BackToHome to="/">BACK TO HOME</BackToHome>
            </PageNotFoundContainer>
            <Description>
                <Box className="description">
                    make sure you typed in the page address correctly or go back to your previous page
                </Box>
            </Description>
        </WrapPageNotFound>
    );
}

const WrapPageNotFound = styled(Box)`
    height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
`

const PageNotFoundContainer = styled(Box)`
    background-image: url('/images/page-not-found.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
`

const Description = styled(Box)`
    border-top: 6px solid #000000;
    border-bottom: 6px solid #000000;
    padding-top: 7px;
    background-color: #D1D1D1;
    box-sizing: border-box;
    padding-bottom: 7px;

    .description {
        font-family: 'Chakra Petch', sans-serif;
        color: #333333;
        text-align: center;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 34px;
        line-height: 203%;
        background-color: #FFFFFF;
        padding-left: 10px;
        padding-right: 10px;
    }

    @media screen and (max-width: 899px) {
        .description {
            font-size: 20px;
            line-height: 150%;
        }
    }
`

const BackToHome = styled(Link)`
    font-family: 'Chakra Petch', sans-serif;
    color: #FFFFFF;
    text-align: center;
    letter-spacing: -0.05em;
    font-weight: 600;
    font-size: 24px;
    line-height: 76%;
    background: #3B62FF;
    padding: 25px 0 24px;
    max-width: 287px;
    width: 100%;
    margin: auto;
    text-decoration: none;
    position: absolute;
    top: 63%;
    right: 10%;

    @media screen and (max-width: 899px) {
        right: 50%;
        transform: translateX(50%);
    }
`

export default PageNotFound;