import React, { useState } from 'react'
import { Box } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { heroSlide } from 'constants/home'
import styled from '@emotion/styled'

import 'swiper/css'

function SectionHero() {
  return (
    <>
    <WrapperSection>

      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: {xs: '150px', sm: '250px'},
        height: {xs: '160px', sm: '260px'},
        backgroundImage: 'url("/images/hesman-studio.png")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        zIndex: 11
      }} />

      <Box sx={{
        position: 'absolute',
        top: 0,
        right: {xs: 0, lg: '50%'},
        width: {xs: '100vw', lg: '50vw'},
        height: {xs: '50vh', lg: '110vh'},
        backgroundImage: 'url("/images/Hesman_02 1.png")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        zIndex: 10
      }} />

      <Box sx={{
        position: 'absolute',
        top: '15%',
        left: '47%',
        width: "40vw",
        zIndex: 2
      }}>
        <Box sx={{ position: 'relative', }}>
          
          <Box sx={{
            position: 'absolute',
            top: '20px',
            left: '-80px',
            width: "150px"
          }}>
            <img src="/images/title-present.png" alt="title-present" />
          </Box>

          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: "45vw",
            zIndex: 1
          }}>
            <img src="/images/super-hero.png" alt="super-hero" />
          </Box>

        </Box>
      </Box>

      <Box sx={{
        position: 'absolute',
        bottom: {xs: 'calc(50vh - 20px)', lg: 'calc(40vh - 20px)'},
        right: {xs: 0, md: '50px'},
        width: "200px",
        height: "150px",
        backgroundImage: 'url("/images/hesman-legend-comic.png")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        zIndex: 13
      }} />

      <WrapperSwiper>
        <Swiper
          spaceBetween={20}
          loop={true}
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
          }}
        >
          {
            heroSlide.map((entry, index) => (
              <SwiperSlide key={index}>
                <BoxImgSlide className='box-slide'>
                  <img src={entry.img} alt="image" />
                </BoxImgSlide>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </WrapperSwiper>

    </WrapperSection>

    <Box sx={{
      fontSize: '22px',
      fontWeight: 'bold',
      textAlign: 'right',
      padding: '10px 20px',
      textTransform: 'uppercase',
      borderBottom: '4px solid #020308',
      borderTop: '4px solid #020308',
    }}>
      over 200 problem solvers to making the better world
    </Box>
    </>
  )
}

const WrapperSwiper = styled(Box)`
  position: absolute;
  overflow: hidden;
  bottom: 30px;
  right: 0;
  width: 50vw;
  z-index: 12;
  & .swiper {
    right: -15%;
  }
  & .swiper-slide.swiper-slide-active .box-slide::after {
    display: none;
  }
  @media (max-width: 1199px) {
    width: 100vw;
    bottom: 5vh;
  }
`

const BoxImgSlide = styled(Box)`
  height: 40vh;
  border: 3px solid #333333;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.6;
    transition: all 0.3s;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 1199px) {
    height: 50vh;
  }
`

const WrapperSection = styled(Box)`
  height: 90vh;
  background-image: url("/images/bg-section-hero.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  img {
    max-width: 100%;
    height: auto;
  }
  @media (max-width: 1199px) {
    height: 100vh;
  }
`

export default SectionHero