import {ReactComponent as FacebookIcon} from 'icons/facebook.svg'
import {ReactComponent as TwitterIcon} from 'icons/twitter.svg'
import {ReactComponent as TelegramIcon} from 'icons/telegram.svg'
import {ReactComponent as YoutubeIcon} from 'icons/youtube.svg'

export const navFooter1 = [
  {
    href: "#BLOG",
    title: "BLOG",
    key: 'BLOG'
  },
  {
    href: "#ROADMAP",
    title: "ROADMAP",
    key: 'ROADMAP'
  },
  {
    href: "#TOKENOMIC",
    title: "TOKENOMIC",
    key: 'TOKENOMIC'
  },
  {
    href: "#WHITE_PAPER",
    title: "WHITE PAPER",
    key: 'WHITE_PAPER'
  },
  {
    href: "#TERMS_OF_USE",
    title: "TERMS OF USE",
    key: 'TERMS_OF_USE'
  }
];

export const navFooter2 = [
  {
    href: "#STAKING",
    title: "STAKING",
    key: 'STAKING'
  },
  {
    href: "#MARKETPLACE",
    title: "MARKETPLACE",
    key: 'MARKETPLACE'
  },
  {
    href: "#MY_PROFILE",
    title: "MY PROFILE",
    key: 'MY_PROFILE'
  },
  {
    href: "#TEAM",
    title: "TEAM",
    key: 'TEAM'
  },
  {
    href: "#PITCH_DECK",
    title: "PITCH DECK OF USE",
    key: 'PITCH_DECK'
  }
];

export const navFooterSocial = [
  {
    link: "https://www.facebook.com/",
    title: "facebook",
    icon: FacebookIcon
  },
  {
    link: "https://twitter.com/",
    title: "twitter",
    icon: TwitterIcon
  },
  {
    link: "https://web.telegram.org/",
    title: "telegram",
    icon: TelegramIcon
  },
  {
    link: "https://www.youtube.com/",
    title: "youtube",
    icon: YoutubeIcon
  },
]