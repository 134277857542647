import { Container, Grid, Box } from '@mui/material'
import { navFooter1, navFooter2, navFooterSocial } from 'constants/navFooter'
import styled from '@emotion/styled'

function Footer() {
  return (
    <WrapperFooter>
      <Container>
        <Grid container spacing={3}>
          <Grid item container xs={12} md={7}>
            <Grid item xs={12} md={6}>
              <Box sx={{ 
                display: 'flex', 
                width: {xs: '100%', md: 'inherit'},
                marginBottom: {xs: '40px', md: '0'},
                '& img': {
                  margin: {xs: '0 auto', md: '0'}
                }
              }}>
                <img src="/logo.svg" className="HesmanLegend-logo" alt="HesmanLegend Logo" />
              </Box>
            </Grid>
            <Grid item container xs={12} md={6} justifyContent={'space-between'}>
              <Box>
              { navFooter1.map((entry) => (
                <NavItem key={entry.title}>
                  {entry.title}
                </NavItem>
              )) }
              </Box>
              <Box sx={{ marginBottom: {xs: '40px', md: '0'}, }}>
              { navFooter2.map((entry) => (
                <NavItem key={entry.title}>
                  {entry.title}
                </NavItem>
              )) }
              </Box>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={5} justifyContent={'center'} alignItems={'center'}>
            { navFooterSocial.map((entry) => (
              <Box key={entry.title} mx={2}>
                <a href={entry.link}>
                  <entry.icon />
                </a>
              </Box>
            )) }
          </Grid>
        </Grid>
      </Container>
    </WrapperFooter>
  )
}

const WrapperFooter = styled(Box)`
  border-top: 4px solid #020308;
  border-bottom: 4px solid #020308;
  padding: 50px 0;
`

const NavItem = styled(Box)`
  letter-spacing: -0.05em;
  color: #333333;
  line-height: 1.6;
`

export default Footer