import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Header from 'components/display/Header'
import Footer from 'components/display/Footer'
import HomePage from 'screens/Home'
import PageNotFound from 'screens/404';
import ComingSoon from 'screens/ComingSoon';

function App() {
  return (
    <BrowserRouter className="App">
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
      </Routes>
      {window.location.pathname !== "/coming-soon" && window.location.pathname !== "/404" ? (
          <Footer />
      ) : null}
    </BrowserRouter>
  );
}

export default App;
