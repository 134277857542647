import SectionHero from "./SectionHero"
import SectionAbout from "./SectionAbout"
import SectionOurMission from "./SectionOurMission"
import SectionOurTeam from "./SectionOurTeam"
import SectionContactUs from "./SectionContactUs"
import SectionOurPartners from "./SectionOurPartners"
import SectionGallery from "./SectionGallery"

function HomePage() {
  return (
    <div>
      <SectionHero />
      <SectionAbout />
      <SectionOurMission />
      <SectionOurTeam />
      <SectionGallery />
      <SectionOurPartners />
      <SectionContactUs />
    </div>
  )
}

export default HomePage