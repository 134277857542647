import { Grid, Box } from '@mui/material'
import TitleSection from 'components/display/Section/Title'
import { Swiper, SwiperSlide } from 'swiper/react'
import { teamSlide } from 'constants/home'
import styled from '@emotion/styled'

import 'swiper/css'
import 'swiper/css/virtual'

function SectionOurTeam() {
  return (
    <WrapperSection>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Box sx={{
            padding: {xs: '0 20px', xl: '0 20px 0 70px'},
            position: 'relative',
            width: {xs: 'inherit', lg: '150%'},
            zIndex: 10,
          }}>
            <Box sx={{ width: '100%' }}>
              <TitleSection firstTitle={'Our'} lastTitle={'Team'} align={'left'} />
            </Box>
            <div>Heatherwick Studio is a team of over 200 problem solvers dedicated to making the physical world around us better for everyone. Based out of our combined workshop and design studio in Central London, we create buildings, spaces, master-plans, objects and infrastructure. Focusing on large scale projects in cities all over the world, we prioritise those with the greatest positive social impact.</div>
          </Box>
        </Grid>
        <Grid item xs={12} lg={9}>
        <WrapperSwiper>
          <Swiper
            spaceBetween={20}
            loop={true}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 4,
              },
            }}
          >
            {
              teamSlide.map((entry, index) => (
                <SwiperSlide key={index}>
                  <BoxImgSlide className='box-slide'>
                    <Box sx={{
                      width: {xs: '200%', md: '260%', xl: '220%'},
                      height: '100%',
                      position: 'absolute',
                      top: 0,
                      left: '-50%',
                      backgroundImage: `url(${entry.img})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      transform: 'skew(20deg)'
                    }} />

                    <Box className='box-info'
                      sx={{
                        width: '100%',
                        height: {xs: '150px', md: '200px'},
                        position: 'absolute',
                        left: '0',
                        bottom: '20px',
                        backgroundImage: `url("/images/cloud-team.png")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center bottom',
                        backgroundSize: 'contain',
                        transform: 'skew(20deg)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }} >
                      <Box sx={{
                        fontSize: '30px',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                      }}>{entry.name}</Box>
                      <Box sx={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                      }}>{entry.position}</Box>
                    </Box>
                  </BoxImgSlide>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </WrapperSwiper>
        </Grid>

      </Grid>
      <Box sx={{
        fontSize: '22px',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '10px 20px',
        textTransform: 'uppercase',
        borderTop: '4px solid #020308',
        marginTop: '20px',
      }}>over 200 problem solvers to making the better world</Box>
    </WrapperSection>
  )
}

const WrapperSwiper = styled(Box)`
  overflow: hidden;
  & .swiper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 22%;
    height: 70vh;
    background-color: #ffffff;
    transform: skew(-20deg);
    z-index: 10;
  }
  & .swiper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 15%;
    height: 50vh;
    background-color: #ffffff;
    z-index: 10;
  }
  & .swiper-slide .box-slide {
    transition: opacity 0.3s;
    opacity: 1;
  }
  & .swiper-slide.swiper-slide-prev .box-slide {
    opacity: 0;
  }
  & .swiper-slide.swiper-slide-active .box-slide {
    opacity: 0;
  }
  & .swiper-slide.swiper-slide-next .box-slide::after {
    display: none;
  }
  & .swiper-slide .box-slide .box-info {
    opacity: 0;
  }
  & .swiper-slide.swiper-slide-next .box-info {
    opacity: 1;
  }
  @media (min-width: 1200px) {
    & .swiper {
      padding-right: 10%;
    }
  }
  @media (max-width: 1199px) {
    & .swiper .swiper-slide {
      right: 20%;
    }
    & .swiper::before {
      width: 50%;
      height: 50vh;
      left: -20%;
      top: -10px;
    }
    & .swiper::after {
      height: 20vh;
      width: 30%;
    }
  }
`

const BoxImgSlide = styled(Box)`
  border: 4px solid #020308;
  transform: skew(-20deg);
  height: calc(70vh - 20px);
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform: skew(20deg);
  }
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.6;
    transition: all 0.3s;
  }
  @media (max-width: 1199px) {
    height: calc(50vh - 20px);
  }
`

const WrapperSection = styled(Box)`
  min-height: 70vh;
  margin-top: 20px;
`

export default SectionOurTeam