import React, { useRef, useState } from "react";
import { Grid, Box, TextField, Button } from '@mui/material'
import styled from '@emotion/styled'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

function SectionGallery() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <Box sx={{ borderTop: '4px solid #020308', borderBottom: '4px solid #020308' }}>
            <SectionOurPartner>
                <TitleSection>Gallery</TitleSection>
                <DescriptionSection>
                    Heatherwick Studio is a team of over 200 problem solvers dedicated to making the physical world around us better for everyone. Based out of our combined workshop and design studio in Central London, we create buildings, spaces, master-plans, objects and infrastructure. Focusing on large scale projects in cities all over the world...
                </DescriptionSection>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Swiper
                            style={{
                                "--swiper-navigation-color": "#fff",
                                "--swiper-pagination-color": "#fff",
                            }}
                            spaceBetween={10}
                            navigation={true}
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper2"
                        >
                            <SwiperSlide>
                                <Img src="/images/HM-Banner-01.png" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Img src="/images/HM-Banner-02.png" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Img src="/images/HM-Banner-03.png" />
                            </SwiperSlide>
                        </Swiper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <GalleryThumbs
                            onSwiper={setThumbsSwiper}
                            direction={"vertical"}
                            spaceBetween={10}
                            slidesPerView={4}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <Img src="/images/HM-Banner-01.png" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Img src="/images/HM-Banner-02.png" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Img src="/images/HM-Banner-03.png" />
                            </SwiperSlide>
                        </GalleryThumbs>
                    </Grid>
                </Grid>
            </SectionOurPartner>
        </Box>
    )
}

const SectionOurPartner = styled(Box)`
    padding: 40px 15px 40px;
`

const TitleSection = styled.h3`
    font-family: 'Chakra Petch', sans-serif;
    letter-spacing: -0.05em;
    color: #333333;
    font-weight: bold;
    font-size: 100px;
    line-height: 76%;
    margin: 0;
    margin-bottom: 20px;
    text-align: center;

    span {
        color: #3B62FF
    }

    @media screen and (max-width: 899px) {
        font-size: 70px;
        line-height: 76%;
    }
`

const GalleryThumbs = styled(Swiper)`
    .swiper-slide-visible {
        height: auto !important;
        background-color: #000000;

        img {
            opacity: .4;
        }
    }

    .swiper-slide-thumb-active {
        img {
            opacity: 1;
        }
    }
`

const DescriptionSection = styled.p`
    max-width: 880px;
    width: 100%;
    font-family: 'Chakra Petch', sans-serif;
    margin: 0 auto;
    font-size: 18px;
    color: #333333;
    text-align: center;
    letter-spacing: 0.05em;
    margin-bottom: 30px;

    @media screen and (max-width: 899px) {
        font-size: 14px;
    }
`

const Img = styled.img`
    width: 100%;
    border: 6px solid #020308;
    box-sizing: border-box;
    display: block;
`

export default SectionGallery