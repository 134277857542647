import React, { useState } from 'react'
import { Container, Box } from '@mui/material'
import { navHeader } from 'constants/navHeader'
import Burger from './burger'
import MenuMb from './menuMb'
import styled from '@emotion/styled'

function Header() {
  const [open, setOpen] = useState(false);

  const scroll = (id) => {
    const section = document.querySelector( `#${id}` );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  return (
    <WrapperHeader>
      <Container>
        <Flex>
          <Box>
            <img src="/logo.svg" className="HesmanLegend-logo" alt="HesmanLegend Logo" />
          </Box>
          <Box sx={{ display: {xs: 'none', md: 'block'} }}>
            <NavList>
              { navHeader.map((entry) => (
                <NavItem key={entry.key} onClick={() => scroll(entry.key)}>
                  {entry.title}
                </NavItem>
              )) }
            </NavList>
          </Box>
          <Box sx={{ display: {xs: 'none', md: 'block'}, position: 'relative', width: '200px'}}>
            <WrapperButton>
              <ButtonJoinNow>
                <span>JOIN NOW</span>
              </ButtonJoinNow>
            </WrapperButton>
          </Box>

          <Box sx={{ display: {xs: 'flex', md: 'none'}, alignItems: 'center' }}>
            <Burger open={open} setOpen={setOpen} />
            <MenuMb open={open} setOpen={setOpen} />
          </Box>
      </Flex>
      </Container>
    </WrapperHeader>
  )
}

const ButtonJoinNow = styled.button`
  width: 200px;
  height: 150px;
  border: 0;
  background-color: transparent;
  background-image: url("/images/JoinNow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  position: relative;
  span {
    position: absolute;
    top: 25px;
    left: 65px;
    font-size: 26px;
    font-weight: bold;
    width: 70px;
    display: inline-block;
  }
`

const WrapperButton = styled(Box)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`

const WrapperHeader = styled.div`
  position: relative;
  margin-bottom: 4px;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #CFCFCF;
    bottom: 0;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #020308;
    bottom: -4px;
  }
`

const Flex = styled(Box)`
  display: flex;
  justify-content: space-between;
`

const NavList = styled.nav`
  display: flex;
  align-items: center;
  height: 100%;
`

const NavItem = styled(Box)`
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0 20px;
`

export default Header