export const navHeader = [
  {
    href: "#hesman-studio",
    title: "hesman studio",
    key: 'hesmanStudio'
  },
  {
    href: "#hesman-legend",
    title: "hesman legend",
    key: 'hesmanLegend'
  },
  {
    href: "#partners",
    title: "partners",
    key: 'partners'
  },
  {
    href: "#contact",
    title: "contact",
    key: 'contact'
  },
];