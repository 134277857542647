import { navHeader } from 'constants/navHeader'
import { Box } from '@mui/material'
import styled from '@emotion/styled'

function MenuMb({ open, setOpen }) {
  const scroll = (id) => {
    setOpen(false);
    setTimeout(() => {
      const section = document.querySelector( `#${id}` );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }, 1000);
  };

  return (
    <StyledMenu open={open}>
      <ButtonJoinNow>
        <span>JOIN NOW</span>
      </ButtonJoinNow>

      <Box px={4} pt={4}>
        { navHeader.map((entry) => (
          <Box key={entry.key} sx={{ textTransform: 'uppercase', lineHeight: 1.6, fontWeight: 'bold'}} onClick={() => scroll(entry.key)}>
            {entry.title}
          </Box>
        )) }
      </Box>
    </StyledMenu>
  )
}

const StyledMenu = styled.nav`
  display: none;
  flex-flow: column wrap;
  background-color: #ffffff;
  position: fixed;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  top: 0;
  right: 0;
  height: 100vh;
  display: block;
  list-style: none;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  z-index: 100;
`

const ButtonJoinNow = styled.button`
  width: 200px;
  height: 150px;
  border: 0;
  background-color: transparent;
  background-image: url("/images/JoinNow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  position: relative;
  span {
    position: absolute;
    top: 25px;
    left: 65px;
    font-size: 26px;
    font-weight: bold;
    width: 70px;
    display: inline-block;
  }
`

export default MenuMb